<template>
	<S2SCard title="Accounts">
		<v-layout row wrap slot="toolbar-content" justify-end mr-4>
			<v-flex xs3 md1>
				<v-btn color="accent" :to="{ name: 'organisation-account-create' }">Add</v-btn>
			</v-flex>
		</v-layout>

		<v-data-table
			:headers="headers"
			:items="accounts"
			hide-default-footer
			class="elevation-1"
			:loading="status.loading"
			show-expand
			single-expand
			@item-expanded="fetchAccount($event.item.id)"
		>
			<template v-slot:item.delete="{ item }">
				<v-btn @click="deleteAccount(item.id)" text>
					<v-icon color="primary" @click="deleteAccount(item.id)">delete</v-icon>
				</v-btn>
			</template>
			<template v-slot:expanded-item="{ item, headers }">
				<td :colspan="headers.length">
					<v-container v-if="selectedAccount && selectedAccount.id === item.id" fluid>
						<v-layout row wrap>
							<v-flex xs12 sm6 v-for="attribute in selectedAccount.accountType.attributes" :key="attribute.key">
								<v-text-field
									readonly
									hide-details
									v-if="attribute.fieldType === 'text' || attribute.fieldType === 'numeric'"
									:name="attribute.key"
									:required="attribute.required"
									:label="attribute.label"
									v-model="editModel[attribute.key]"
								></v-text-field>
								<v-text-field
									readonly
									hide-details
									v-else-if="attribute.fieldType === 'password'"
									type="password"
									:name="attribute.key"
									:required="attribute.required"
									:label="attribute.label"
									v-model="editModel[attribute.key]"
								></v-text-field>
								<v-select
									readonly
									hide-details
									v-else-if="attribute.fieldType === 'option'"
									:items="sanitizeLookup(attribute.validation)"
									:label="attribute.label"
									v-model="editModel[attribute.key]"
								></v-select>
							</v-flex>
							<!-- <v-flex xs12 class="text-sm-right">
								<v-btn color="accent" @click="onSave()">Save</v-btn>
							</v-flex>-->
						</v-layout>
					</v-container>
				</td>
			</template>
			<template slot="footer">
				<td :colspan="headers.length"></td>
			</template>
		</v-data-table>
	</S2SCard>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "Accounts",

	computed: {
		accounts: function() {
			return this.$store.state.organisations.accounts.accounts;
		},
		selectedAccount: function() {
			return this.$store.state.organisations.accounts.selectedAccount;
		},
		status: function() {
			return this.$store.state.organisations.accounts.status;
		}
	},

	watch: {
		selectedAccount: function() {
			const props = this.selectedAccount.properties;
			for (let item of props) {
				this.editModel[item.key] = item.value;
			}
		}
	},

	data: function() {
		return {
			editModel: {},

			headers: [
				{
					text: "Account Type",
					value: "method"
				},
				{
					text: "Name",
					value: "name"
				},
				{
					text: "Delete",
					value: "delete"
				}
			]
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/accounts/fetchAccounts");
	},

	methods: {
		onRowExpand(event) {
			this.fetchAccount(event.item.id);
		},
		onSave() {
			const apiObj = {};
			const properties = [];
			for (let item in this.editModel) {
				properties.push({ key: item, value: this.editModel[item] });
			}

			apiObj.name = this.selectedAccount.name;
			apiObj.holder = this.selectedAccount.holder;
			apiObj.properties = properties;
			// apiObj.overdraftLimit = 0;
			apiObj.validationStatus = { status: this.selectedAccount.validationStatus.status, reason: "" };

			this.$store.dispatch("organisations/accounts/updateAccount", apiObj);
		},
		fetchAccount(accountId) {
			this.$store.dispatch("organisations/accounts/fetchAccount", accountId);
		},
		deleteAccount(accountId) {
			this.$store.dispatch("organisations/accounts/deleteAccount", accountId);
		},
		sanitizeLookup(lookupData) {
			const lookupJson = JSON.parse(lookupData);

			const lookups = [];
			for (let lookup in lookupJson) {
				lookups.push(lookupJson[lookup]);
			}
			return lookups;
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-text-field >>> .v-input__control > .v-input__slot:after,
.v-text-field >>> .v-input__control > .v-input__slot:before {
	border-style: none !important;
}
</style>
